.hostsSectionContainer {
    max-width: 1140px;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 6.25rem 1.875rem 3.5rem 1.875rem;
}

.hostsSectionBanner {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 30px;
}

.hostsContainer {
    display: flex;
    justify-content: center;
    animation-duration: 0.5s;
    animation-name: slidein;
}

@media only screen and (max-width: 1100px) {
    .hostsContainer {
        flex-wrap: wrap;
        max-width: 1100px;
    }
}

@media only screen and (max-width: 900px) {
    .hostsContainer {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 600px) {
    .hostsSectionBanner {
        text-align: center;
    }
}

@media only screen and (max-width: 414px) {
    .hostsContainer {
        max-width: 300px;
    }
}
