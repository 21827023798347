.latestEpContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    margin-top: 60px;
    max-width: 1200px;
    padding: 0 1.875rem;
}

.latestEpisodesCarouselContainer {
    animation-duration: 0.5s;
    animation-name: slidein;
}

.latestEpisodesCarouselContainer button.carouselButton:nth-child(3) + div {
    gap: 5px;
    flex-wrap: wrap;
}

.latestEpHeadings {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.latestEpHeader {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 30px;
    font-weight: 700;
}

.browseAll {
    color: #908dab;
    display: flex;
    font-family: "Anek Latin", sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin-top: 12px;
}

.browseAll:hover {
    color: #01b7cc;
}

.latestEpButtonContainer {
    display: flex;
    align-items: flex-end;
}

.carouselButton {
    display: none;
}

.episodesContainer {
    display: flex;
    margin: auto;
    overflow-x: scroll;
    max-width: 1140px;
}
@media only screen and (max-width: 1200px) {
    .latestEpContainer {
        margin: 0;
    }
    .latestEpisodesCarouselContainer {
        justify-content: space-between;
    }
}

@media only screen and (max-width: 600px) {
    .latestEpisodesCarouselContainer {
        padding: 0 2.5rem;
    }
    .latestEpHeadings {
        flex-direction: column;
        padding-bottom: 10px;
    }
    .latestEpHeader {
        text-align: center;
    }
    .browseAll {
        justify-content: center;
    }
}
