.logo {
    display: block;
    height: 15px;
    padding-right: 8px;
}

.listenOnChannel {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 700;
    margin-right: 12px;
    padding-top: 10px;
    text-transform: uppercase;
}

@media only screen and (max-width: 414px) {
    .listenOnChannel {
        font-size: 10px;
        margin-right: 10px;
    }
}

@media only screen and (max-width: 360px) {
    .listenOnChannel {
        font-size: 8px;
        margin-right: 8px;
    }
}
