@keyframes slidein {
    0% {
        transform: translate3d(0, 100px, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

#root {
    display: flex;
    flex-direction: column;
}

body {
    background-color: #f6fafc;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::selection {
    background: #faaf40;
}

.bold {
    font-weight: bold;
}

.outerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.innerContainer {
    max-width: 1200px;
    margin: auto;
}

.youtubeIcon {
    height: 12px;
    padding: 10px 8px 0 0;
}

.projectsListenOnContainer {
    display: flex;
    align-items: center;
}

.projectDescription {
    font-size: 18px;
    font-weight: 400;
    padding: 0px 50px 0 50px;
    max-width: 600px;
}

.linkBold {
    color: #faaf40;
    font-weight: bold;
}

.linkBold:hover {
    text-decoration: underline;
}

.episodePlayButton:hover {
    color: white;
    background-color: #01b7cc;
}

.projectHeader {
    padding-bottom: 30px;
    max-height: 800px;
    max-width: 1500px;
}

.listenOnIconAndTitle {
    padding-top: 10px;
}

.listenOnIconChannel {
    display: flex;
    padding-top: 5px;
}

.embedVideo {
    width: 425px;
    height: 300px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
@media only screen and (min-width: 1500px) {
    .projectHeader {
        margin: auto;
    }
}

@media only screen and (max-width: 600px) {
    .listenOnChannel {
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 700px) {
    .projectDescription {
        padding: 0;
    }
}

@media only screen and (max-width: 500px) {
    .carouselButton {
        display: none;
    }
}

@media only screen and (max-width: 414px) {
    .embedVideo {
        width: 349px;
        height: 247px;
        margin: 10px;
    }
    .projectHeader {
        max-height: 160px;
    }
}
