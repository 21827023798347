.footerContainer {
    font-family: "Helvetica Neue", sans-serif;
    max-width: 1140px;
    margin: auto;
    padding-top: 50px;
}

.footerLinksContainer {
    display: flex;
    justify-content: center;
    max-width: 1100px;
    margin: 0 3rem;
}

.footerLinks {
    list-style: none;
    padding: 0 10px;
}

.footerLinksHeader {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 7px;
    text-transform: uppercase;
}

.footerLink {
    color: rgb(149, 148, 148);
    font-weight: 500;
    padding: 5px 5px 5px 0;
}

.footerLink:hover {
    color: #faaf40;
}

.footerHR {
    max-width: 1140px;
    margin: 0 1.875rem;
}

.footerCopyright {
    color: #faaf40;
    font-weight: 600;
    text-align: center;
}

@media only screen and (min-width: 1000px) {
    .footerContainer {
        width: 800px;
    }
    .footerLinksContainer {
        justify-content: flex-end;
    }
}

@media only screen and (min-width: 1200px) {
    .footerContainer {
        width: 1140px;
    }
    .footerLinksContainer {
        justify-content: flex-end;
    }
}
