.navContainer {
    display: flex;
    justify-content: flex-end;
    height: 5rem;
    width: 100%;
    margin-bottom: 0.6rem;
    z-index: 9;
}

.navMainContainer {
    display: flex;
}

.navPatreonLogo {
    height: 1.0625rem;
    padding-right: 0.5rem;
}

.patreonDonate {
    align-items: center;
    background-color: #faaf40;
    border: none;
    border-radius: 30px;
    color: white;
    display: flex;
    font-size: 0.8rem;
    font-weight: bolder;
    height: 3.125rem;
    justify-content: center;
    margin: 0.95rem 0.9375rem 0 0;
    text-align: center;
    text-transform: uppercase;
    width: 10.625rem;
}

a:link {
    color: inherit;
    text-decoration: none;
}

.patreonDonate:hover {
    box-shadow: 0 0px 10px 0 #fab040;
}

.navLinks {
    color: #faaf40;
    display: flex;
    flex-direction: row;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
    list-style: none;
    padding-top: 0.3375rem;
}

.navLink {
    color: inherit;
    padding-right: 35px;
}

.navLinkOuter:hover {
    color: #f79707;
    text-decoration: underline;
}

.navHamburger {
    display: none;
    position: relative;
    width: 100%;
}

.navbarHamburgerButton {
    border: none;
    background: transparent;
    margin-right: 1.5rem;
    margin-left: auto;
}

.navbarHamburgerIcon {
    height: 20px;
    padding-top: 0.5rem;
}

.navbarHamburgerLogo {
    height: 65px;
    align-self: center;
    padding-top: 10px;
    padding-left: 1rem;
}

.navHamburgerLinks {
    top: 5.4rem;
    flex-direction: column;
    position: absolute;
    background-color: #faaf40;
    width: 100%;
    display: none;
}

.hamburgerLink {
    border-bottom: 2px solid #ffdea5;
    border-left: none;
    height: 40px;
    width: 100%;
    font-size: 22px;
    padding-top: 10px;
}

.hamburgerLink:hover {
    font-weight: bold;
}

.hamburgerLinkText {
    padding-left: 1rem;
}

.hidden {
    display: flex;
}

@media only screen and (max-width: 1000px) {
    .navContainer {
        justify-content: baseline;
    }
    .navMainContainer {
        display: none;
    }
    .navHamburger {
        display: flex;
    }
}
