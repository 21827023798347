.episodeContainer {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 0px 20px 0 rgba(79, 73, 63, 0.5);
    cursor: grab;
    display: flex;
    flex-direction: column;
    height: 375px;
    margin: 12px;
    padding: 20px;
    position: relative;
    width: 300px;
}

.episodeImg {
    border-radius: 20px;
    height: 300px;
    width: 300px;
}

.episodeDate {
    background: rgba(7, 8, 8, 0.4);
    border-radius: 20px;
    color: #fff;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    padding: 4px 15px;
    position: absolute;
    right: 30px;
    text-transform: uppercase;
    top: 30px;
    white-space: nowrap;
}

.episodePlayButton {
    align-items: center;
    background-color: rgba(5, 172, 172, 0.6);
    border-radius: 100%;
    border: none;
    bottom: 85px;
    height: 60px;
    left: 20px;
    display: flex;
    justify-content: space-around;
    position: relative;
    transition: all 0.4s;
    width: 60px;
}

.episodePlayIcon {
    color: rgb(121, 120, 120);
    font-size: 45px;
}

.episodePlayIcon:hover {
    color: white;
}

.episodeInfo {
    display: flex;
    flex-direction: column;
    margin-top: -50px;
}

.episodeCategory {
    color: #01b7cc;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.episodeTitle {
    font-family: "Helvetica Neue", sans-serif;
    font-size: large;
    font-weight: 600;
    line-height: 30px;
    color: #061020;
}

@media only screen and (max-width: 414px) {
    .episodeContainer {
        height: 335px;
        width: 275px;
    }
    .episodeImg {
        height: 260px;
        width: 275px;
    }
    .episodeTitle {
        font-size: medium;
    }
}
