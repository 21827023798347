.hostContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 20%;
}

.hostImg {
    border-radius: 100%;
    height: 190px;
    width: 190px;
}

.hostName {
    font-family: "Helvetica Neue", sans-serif;
}

.hostBio {
    text-align: center;
    font-size: small;
    width: 175px;
    padding: 0 15px;
}

@media only screen and (max-width: 1100px) {
    .hostContainer {
        margin: 15px;
    }
}

@media only screen and (max-width: 900px) {
    .hostContainer {
        padding: 40px;
    }
}

@media only screen and (max-width: 600px) {
    .hostImg {
        height: 175px;
        width: 175px;
    }
}
