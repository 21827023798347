.patreonContainer {
    display: flex;
    max-width: 1140px;
    margin: auto;
    margin-bottom: 100px;
    padding: 0 1.875rem;
}

.patreonImg {
    border-radius: 20px;
    max-width: 520px;
    max-height: 390px;
    margin-top: 50px;
    box-shadow: 10px 10px 20px 0 rgba(55, 51, 44, 0.5);
}

.patreonTextContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    padding: 70px 0 0 100px;
    text-align: center;
}

.patreonHeading {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
}

.patreonText {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

.patreonButton {
    align-items: center;
    background-color: #faaf40;
    border: none;
    border-radius: 30px;
    color: white;
    display: flex;
    font-size: small;
    font-weight: bolder;
    height: 60px;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    width: 180px;
}

.patreonButton:hover {
    box-shadow: 0 0px 10px 0 #fab040;
}

@media only screen and (max-width: 1000px) {
    .patreonContainer {
        align-items: center;
        flex-direction: column;
    }
    .patreonImg {
        max-height: 390px;
    }
    .patreonTextContainer {
        padding-left: 0;
    }
}

@media only screen and (max-width: 600px) {
    .patreonImg {
        height: 250px;
        width: 350px;
    }
    .patreonHeading {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 400px) {
    .patreonImg {
        height: 210px;
        width: 300px;
    }
}
