.listenOnAudioContainer {
    align-items: center;
    display: flex;
}

.listenOnIconAndTitle {
    font-size: 16px;
    padding-right: 10px;
}

.podIcon {
    padding-right: 5px;
}

a:visited {
    color: inherit;
}

.listenOnBrowseAll {
    color: #908dab;
    font-family: "Anek Latin", sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-top: -5px;
    padding-left: 8px;
}

.listenOnBrowseAll:hover {
    color: #01b7cc;
}

.listenOnDiscord {
    align-items: center;
    display: flex;
    font-size: 15px;
    font-weight: 700;
    margin: 20px 0;
    text-transform: uppercase;
    padding-left: 5px;
}

.listenOnDiscord:hover {
    color: #5865f2;
}

.listenOnDiscordText {
    margin: 0;
}

@media only screen and (max-width: 414px) {
    .listenOnIconAndTitle {
        font-size: 14px;
    }
    .listenOnDiscord {
        font-size: 13px;
    }
}
