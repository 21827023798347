@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinnerContainer {
    position: fixed;
    top: 50%;
    left: 45%;
}

.loadingSpinner {
    width: 60px;
    height: 60px;
    border: 7px solid #faaf40;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
