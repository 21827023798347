.heroOuterContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

.heroContainer {
    display: flex;
    max-width: 1140px;
    margin: auto;
    padding: 0 1.875rem;
}

.heroText {
    display: flex;
    align-items: center;
    font-family: "Helvetica Neue", sans-serif;
    flex-direction: column;
}

.heroMainText {
    display: flex;
    justify-content: center;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 3.25rem;
    text-align: center;
    margin-bottom: 1.5625rem;
}

.heroImageMediaQ {
    border-radius: 20px;
    box-shadow: 10px 10px 20px 0 rgba(55, 51, 44, 0.5);
    max-height: 470px;
    object-fit: cover;
    margin: 55px 0 0 50px;
}

@media only screen and (min-width: 1100px) {
    .heroOuterContainer {
        max-width: 1140px;
        margin: auto;
        padding: 0 1.875rem;
    }
}

@media only screen and (max-width: 1100px) {
    .heroOuterContainer {
        flex-direction: column;
    }
    .heroImageMediaQ {
        border-radius: 0;
        margin: 0;
    }
    .heroSubText {
        text-align: center;
    }
}

@media only screen and (max-width: 414px) {
    .heroContainer {
        justify-content: center;
        margin: 0;
    }
    .heroImageMediaQ {
        border-radius: 0;
        width: 100%;
        margin-top: 0;
    }
}
