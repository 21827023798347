.playbackContainer {
    background-color: black;
    height: 110px;
    width: 100%;
    position: sticky;
    bottom: 0;
}

.playbackInnerContainer {
    align-items: center;
    display: flex;
    height: 110px;
    justify-content: center;
    max-width: 1140px;
    margin: auto;
}

.playbackEpInfo {
    display: flex;
    flex-direction: column;
    padding-right: 150px;
}

.playbackEpTitle {
    color: white;
    font-family: "Helvetica Neue", sans-serif;
    font-size: large;
    font-weight: 600;
    line-height: 30px;
}

.playbackEpCategory {
    color: #01b7cc;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.playbackControl {
    width: 600px;
}

.playbackClose {
    background-color: black;
    color: white;
    position: absolute;
    right: 0px;
    top: 0px;
}

@media only screen and (max-width: 1200px) {
    .playbackEpTitle {
        margin-left: 10rem;
        line-height: 18px;
    }
    .playbackEpCategory {
        margin-left: 10rem;
    }
    .playbackControl {
        margin-right: 2rem;
        min-width: 120px;
    }
    .playbackEpInfo {
        padding-right: 100px;
    }
}

@media only screen and (max-width: 1000px) {
    .playbackEpTitle {
        margin-left: 2rem;
    }
    .playbackEpCategory {
        margin-left: 2rem;
    }
    .playbackControl {
        margin-right: 2rem;
    }
}

@media only screen and (max-width: 750px) {
    .playbackEpTitle {
        font-size: 14px;
        line-height: 18px;
    }
    .playbackEpCategory {
        font-size: 10px;
    }
    .playbackControl {
        margin-right: 2rem;
        min-width: 120px;
    }
    .playbackEpInfo {
        padding-right: 40px;
    }
}

@media only screen and (max-width: 414px) {
    .playbackEpTitle {
        font-size: 12px;
        margin-left: 1.5rem;
        line-height: 16px;
    }
    .playbackEpCategory {
        margin-left: 1.5rem;
        font-size: 10px;
    }
    .playbackControl {
        margin-right: 1.5rem;
        width: 250px;
    }
    .playbackEpInfo {
        padding-right: 12px;
    }
}
